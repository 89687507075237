import React, { useRef } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { StyleSheetManager } from 'styled-components';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import path from 'path';

import { defineComponentMapping } from '../import-components';
import { canUseDOM, CustomModelClient, respGridUtil } from '@marriott/mi-headless-utils';
import { createPageModelStore, PageModelContext, PageModelState, StoreApi } from '@marriott/mi-store-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import './styles.scss';

path.resolve('./next.config.js');
declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const { model, isAuthorMode } = pageProps;
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const store = useRef<StoreApi<PageModelState>>();
  const initialPageModel = {
    pageModel: respGridModel,
  };

  if (!store.current) {
    store.current = createPageModelStore(initialPageModel);
  }

  const rtlPluginTs = (...args: unknown[]) => {
    return rtlPlugin(args[0] as number, args[1] as string);
  };

  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  const envVars = {
    PPv5: 'true',
  };
  setEnvVars?.(envVars);

  defineComponentMapping(allowedComponents, isAuthorMode, pageProps);

  Object.defineProperty(rtlPluginTs, 'name', { value: 'rtlPluginMonorepo' });

  return (
    <PageModelContext.Provider value={store.current}>
      <StyleSheetManager
        stylisPlugins={model?.rtlEnable === 'true' || pageProps?.rtlEnable === 'true' ? [rtlPluginTs] : []}
      >
        <Component {...pageProps} />
      </StyleSheetManager>
    </PageModelContext.Provider>
  );
};

export default App;
