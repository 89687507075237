/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useContext, useEffect, useState } from 'react';
import {
  Config,
  EditableComponent,
  MappedComponentProperties,
  ResponsiveGrid,
} from '@adobe/aem-react-editable-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OfferCardCarousel } from '@marriott/mi-offers-components';
import { canUseDOM } from '@marriott/shared/mi-helper-utils';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import {
  experienceFragmentComponentTypes,
  merchandisingContectID,
  merchandisingLuxuryBrand,
} from '../../modules/utils/constants';
import { PageParamsContext } from '../../modules/context/PageContext';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';
import { LocalServices } from '../LocalServices';
import { LetsConnect } from '../LetsConnect';
import { PropertyPortalExperienceFragment } from '../PropertyPortalExperienceFragment';
import { PropertyPortalContainerProps } from './PropertyPortalContainer.types';
import { StyledPropertyPortalContainer } from './PropertyPortalContainer.styles';
import { ConnectContainer } from '../ConnectContainer';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

// Use named rather than default exports.
export const PropertyPortalContainer: FC<PropertyPortalContainerProps> = (pageProps: PropertyPortalContainerProps) => {
  const { preProcessorResponse, requestId } = useContext(PageParamsContext);
  const { state, mac, cna, userSegment, brandCode, marsha, headersData, locale, userState } =
    preProcessorResponse || {};
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  const [showContainer, setShowContainer] = useState<boolean>(true);
  const [hideMerchandising, setHideMerchandising] = useState(false);
  //Method for returning Responsive Grid for showing parsys in Author mode
  const PropertyPortalContainer = () => {
    const resourceType = `mi-aem-ppv5-spa/components/content/experiencefragment`;

    return (
      <div data-testid="property-portal-container-component">
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pageProps?.pagePath}
          itemPath={`${pageProps?.itemPath}/container-0`}
          config={{
            isEmpty: () => true,
            resourceType: resourceType,
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL
  const PropertyPortalContainerWrapper = (cardName: string, jsonData: any, contentID: string | undefined) => {
    const returnable: Array<any> = [];
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        const item = cardItems[itemKey];
        const type = cardItems?.[itemKey]?.[':type'];
        if (type === experienceFragmentComponentTypes?.experiencefragment) {
          returnable.push(<PropertyPortalExperienceFragment {...item} key={itemKey} contentID={contentID} />);
          // eslint-disable-next-line no-prototype-builtins
        } else if (type === experienceFragmentComponentTypes?.letsConnect) {
          returnable.push(<LetsConnect {...item} key={itemKey} />);
        } else if (type === experienceFragmentComponentTypes?.serviceCarousel) {
          returnable.push(<LocalServices {...item} key={itemKey} contentID={contentID} />);
        } else if (type === experienceFragmentComponentTypes?.offers) {
          const obj = {
            model: item,
          };
          returnable.push(
            <OfferCardCarousel
              {...obj}
              key={itemKey}
              offersData={item?.productSpecificResponse ?? item?.fallbackOfferIdsList ?? []}
              isAuthorMode={pageProps?.isAuthorMode}
              acceptLanguage={locale ?? 'en-US'}
              requestId={requestId}
              cookie={canUseDOM ? document?.cookie : headersData.cookie}
            />
          );
        } else if (cardItems?.[itemKey]) {
          returnable.push(<ConnectContainer {...item} key={itemKey} contentID={contentID} />);
        }
      }
      return <div data-testid="brand-container-component-wrapper">{returnable}</div>;
    }
    return null;
  };

  // Property Portal Brand Container Rules
  // Brand Config always has precedence over the global doHide and selected-states
  // If any condition in the brand-config is true then ignore doHide and selected-states
  // Else any condition in the brand-config is false then check doHide and selected-state
  useEffect(() => {
    if (!pageProps?.isAuthorMode) {
      const { brandConfigCards = [], doHide = false, selectedStates = [] } = pageProps ?? {};
      let brandConfigCardMatch = false;
      const updatedState = state?.replace('_', '-');
      const isCNA = cna;
      const isMAC = mac;
      const updatedUserSegment = userSegment?.toLowerCase()?.replaceAll(' ', '');

      if (brandConfigCards && brandConfigCards?.length) {
        for (let i = 0; i < brandConfigCards?.length; i++) {
          const brandConfigCard = { ...brandConfigCards[i] };
          const stateSelector = brandConfigCard?.stateSelector?.toLowerCase();

          const brandStateMatch =
            stateSelector === updatedState ||
            (isCNA && stateSelector === 'cna') ||
            (isMAC && stateSelector === 'mac') ||
            (updatedUserSegment === 'loggedin' && stateSelector === 'loggedin') ||
            (updatedUserSegment === 'notloggedin' && stateSelector === 'nonloggedin') ||
            (userState === 'basic' && stateSelector === 'userstate-basic') ||
            (userState === 'elite' && stateSelector === 'userstate-elite');

          const selectorBrandCodes = brandConfigCard?.selectorBrandCodes?.toLowerCase()?.split(',');
          const brandCodeMatch = selectorBrandCodes?.includes(brandCode?.toLowerCase());

          const brandMarshaCodes = brandConfigCard?.brandMarshaCodes?.split(',');
          const marshaCodeMatch = brandMarshaCodes?.includes(marsha?.toLowerCase());

          if ((brandStateMatch && marshaCodeMatch) || (brandStateMatch && brandCodeMatch)) {
            if (brandConfigCard?.brandSelectVisibilityMarsha?.toLowerCase() === 'hide') {
              setShowContainer(false);
              setHideMerchandising(true);
            } else {
              setShowContainer(true);
            }
            brandConfigCardMatch = true;
            break;
          }
        }
      }

      if (!brandConfigCardMatch) {
        const stateMatch =
          selectedStates?.includes(updatedState) ||
          (isCNA && selectedStates?.includes('cna')) ||
          (isMAC && selectedStates?.includes('mac')) ||
          (updatedUserSegment === 'loggedin' && selectedStates?.includes('loggedin')) ||
          (updatedUserSegment === 'notloggedin' && selectedStates?.includes('nonloggedin')) ||
          (userState === 'basic' && selectedStates?.includes('userstate-basic')) ||
          (userState === 'elite' && selectedStates?.includes('userstate-elite'));

        if (stateMatch) {
          if (doHide) {
            setShowContainer(false);
            setHideMerchandising(true);
          } else {
            setShowContainer(true);
          }
        }
      }

      if (pageProps?.contentID === merchandisingContectID) {
        if (brandCode && merchandisingLuxuryBrand.includes(brandCode)) {
          setShowContainer(false);
          setHideMerchandising(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (hideMerchandising && pageProps?.contentID === merchandisingContectID) {
      setServiceData(
        {
          merchandisingAvailable: true,
        },
        false,
        false,
        {}
      );
    }
  }, [hideMerchandising]);

  const showPropertyPortalContainer = pageProps.isAuthorMode ? (
    <div>{Array.from({ length: 1 }, _ => PropertyPortalContainer())}</div>
  ) : (
    showContainer && (
      <div className={`${pageProps?.appliedCssClassNames ?? ''}`}>
        {pageProps?.cqItemsOrder &&
          PropertyPortalContainerWrapper(pageProps?.cqItemsOrder?.[0], pageProps?.cqItems, pageProps?.contentID)}
      </div>
    )
  );

  return (
    <StyledPropertyPortalContainer data-component-name="o-ppv5-brandedcontainer">
      <div className={pageProps?.appliedCssClassNames}>{showPropertyPortalContainer}</div>
    </StyledPropertyPortalContainer>
  );
};

export const PropertyPortalConfig = {
  emptyLabel: 'PropertyPortalContainerComponent',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/brandedcontainer`,
} as Config<MappedComponentProperties>;

export const PropertyPortalContainerEditable = (props: PropertyPortalContainerProps) => (
  <EditableComponent config={PropertyPortalConfig} {...props}>
    <PropertyPortalContainer {...props} />
  </EditableComponent>
);
