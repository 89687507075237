/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Config,
  EditableComponent,
  MappedComponentProperties,
  ResponsiveGrid,
} from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { heroBannerQuery } from '../../modules/graphql/index';
import { HeroBannerProps, RenditionsProps, heroBannerImageType } from './HeroBanner.types';
import { StyledHeroBanner } from './HeroBanner.styles';
import { Image } from '@marriott/mi-ui-library';
import { constants, internetBarHeight, cacheURL } from '../../modules/utils/constants/constants';
import { PageParamsContext } from '../../modules/context/PageContext';
import { InternetBar } from '../InternetBar';
import { imageQueryParam } from '../LocalServices/LocalServices.types';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const HeroBanner: FC<HeroBannerProps> = pageProps => {
  const { preProcessorResponse, sessionID, requestId, lang } = useContext(PageParamsContext);
  const isServer = !(typeof window != 'undefined' && window?.document);
  const [heroBannerImage, setHeroBannerImage] = useState<heroBannerImageType | null>(null);
  const internetBarContainerRef = useRef(null);
  const heroBannerContainerRef = useRef(null);
  const [imagedata, setImageData] = useState<any>(null);

  const { data, loading, error } = useQuery(heroBannerQuery, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: preProcessorResponse?.marsha ?? '',
    },
    context: {
      headers: {
        'accept-language': lang ?? 'en-US',
        'x-b3-traceId': sessionID ?? 'fallback-token',
        'x-b3-spanId': requestId !== '' ? requestId : `${Date.now()}`,
        'correlation-id': sessionID ?? 'fallback-token',
      },
    },
    skip: pageProps?.isAuthorMode,
  });

  useEffect(() => {
    if (!pageProps?.isAuthorMode && !loading && !error && data) {
      setImageData(data?.property?.media?.internetPortalPrimaryImage);
    }
  }, [loading, error, data, pageProps?.isAuthorMode]);

  useEffect(() => {
    let heroImage = null;
    if (!loading) {
      if (imagedata && imagedata.length > 0 && imagedata?.[0]?.imageUrls?.classicHorizontal) {
        const dacImageQueryParamsHeroBanner: Array<imageQueryParam> = pageProps?.imageQueryParamsHeroBanner ?? [];
        const wideHorizontal = imagedata?.[0]?.imageUrls?.wideHorizontal;
        const classicHorizontal = imagedata?.[0]?.imageUrls?.classicHorizontal;
        const square = imagedata?.[0]?.imageUrls?.square;

        heroImage = {
          altText: imagedata?.[0]?.alternateDescription,
          assetPath: imagedata?.[0]?.imageSrc,
          dynamic: false,
          renditions: dacImageQueryParamsHeroBanner.map(imageQueryParams => {
            const viewportQuery = imageQueryParams?.viewPortName?.split(':')[0];
            const viewportValue = imageQueryParams?.viewPortName?.split(':')[1];
            const mediaVal = Number(viewportValue.slice(0, imageQueryParams?.viewPortName?.split(':')[1].length - 2));
            const imagePath =
              mediaVal >= 992
                ? wideHorizontal
                : mediaVal === 576 && viewportQuery === 'min-width'
                ? classicHorizontal
                : mediaVal === 576 && viewportQuery === 'max-width'
                ? square
                : '';
            return {
              mediaQuery: viewportQuery,
              mediaValue: viewportValue,
              dynamic: false,
              renditionPath: `${cacheURL}${imagePath}?${imageQueryParams?.nonRetinaDisplayParams}, ${cacheURL}${imagePath}?${imageQueryParams?.retinaDisplayParams}`,
            };
          }),
        };
      } else if (
        pageProps?.authoredImage &&
        pageProps?.authoredImage?.renditions &&
        pageProps?.authoredImage?.renditions?.length > 0
      ) {
        heroImage = { ...pageProps?.authoredImage };
      } else if (
        pageProps?.fallbackBrandImage &&
        pageProps?.fallbackBrandImage?.renditions &&
        pageProps?.fallbackBrandImage?.renditions?.length > 0
      ) {
        heroImage = { ...pageProps?.fallbackBrandImage };
      } else if (
        pageProps?.dynamicImageFileReference &&
        pageProps?.dynamicImageFileReference?.renditions &&
        pageProps?.dynamicImageFileReference?.renditions?.length > 0
      ) {
        heroImage = { ...pageProps?.dynamicImageFileReference };
      }

      if (
        (!imagedata || imagedata?.length === 0) &&
        heroImage &&
        heroImage?.assetPath &&
        heroImage?.assetPath?.includes('/is/')
      ) {
        const imageQueryParamsHeroBanner: Array<imageQueryParam> = pageProps?.imageQueryParamsHeroBanner ?? [];
        const imageData: heroBannerImageType = { ...heroImage };
        const renditions = imageData?.renditions;
        const newRenditions: Array<RenditionsProps> | null = [];
        imageQueryParamsHeroBanner?.forEach(imageQueryParamHeroBanner => {
          let queryValue = imageQueryParamHeroBanner?.viewPortName?.split(': ')[1] ?? '';
          if (imageQueryParamHeroBanner?.viewPortName?.split(':')[0] === 'min-width' && queryValue === '576px') {
            queryValue = '577px';
          }
          let matchMedia = renditions?.find(rend => rend?.mediaValue === queryValue);

          if (matchMedia) {
            matchMedia = {
              ...matchMedia,
              renditionPath: `${matchMedia?.renditionPath}?${imageQueryParamHeroBanner?.nonRetinaDisplayParams}, ${matchMedia?.renditionPath}?${imageQueryParamHeroBanner?.retinaDisplayParams}`,
            };
            newRenditions.push(matchMedia);
          }
        });
        heroImage = { ...imageData, renditions: newRenditions };
      }
      setHeroBannerImage(heroImage);
    }
  }, [imagedata, pageProps, loading]);

  //Method for returning Responsive Grid for showing parsys in Author mode
  const InternetBarContainer = () => {
    return (
      <div data-testid="brand-container-component">
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pageProps?.pagePath}
          itemPath={`${pageProps?.itemPath}/container-0`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const InternetBarContainerWrapper = (cardName: string, jsonData: any) => {
    const returnable = [];
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          returnable.push(<InternetBar {...item} key={itemKey} />);
        }
      }
      return <div data-testid="brand-container-component-wrapper">{returnable}</div>;
    }
    return null;
  };

  const showInternetBarContainer = pageProps.isAuthorMode ? (
    <div>{Array.from({ length: 1 }, _ => InternetBarContainer())}</div>
  ) : (
    <div>
      {pageProps?.cqItemsOrder && InternetBarContainerWrapper(pageProps?.cqItemsOrder?.[0], pageProps?.cqItems)}
    </div>
  );

  useLayoutEffect(() => {
    if (!loading && heroBannerImage) {
      const internetBarContainerHeight =
        (internetBarContainerRef?.current as unknown as HTMLElement)?.querySelector('.internet-bar-container')
          ?.clientHeight ?? 0;
      const heroBannerContainerHeight = (heroBannerContainerRef?.current as unknown as HTMLElement)?.clientHeight ?? 0;
      const heroBannerImageStyle = (heroBannerContainerRef?.current as unknown as HTMLElement)?.querySelector(
        'picture img'
      );
      if (heroBannerContainerHeight < internetBarContainerHeight + internetBarHeight / 2) {
        (heroBannerContainerRef?.current as unknown as HTMLElement).style.height = `${
          internetBarContainerHeight + internetBarHeight
        }px`;

        if (heroBannerImageStyle) {
          (heroBannerImageStyle as HTMLElement).style.height = `${internetBarContainerHeight + internetBarHeight}px`;
        }
      }
    }
  }, [heroBannerImage, loading]);

  return (
    <StyledHeroBanner data-testid="hero-banner" data-component-name="o-ppv5-hero-banner">
      <div className="hero-component">
        <div className="hero-component-container" ref={heroBannerContainerRef}>
          {!loading && heroBannerImage && (
            <Image
              defaultImageURL={constants.BASE_IMG_URL}
              altText={heroBannerImage.altText}
              dynamic={true}
              renditions={heroBannerImage?.renditions ?? []}
              loading="eager"
              customClass="hero-component-img col-12 p-0 loaded"
            ></Image>
          )}
          <div className="hero-component__internetbar" ref={internetBarContainerRef}>
            {showInternetBarContainer}
          </div>
        </div>
      </div>
    </StyledHeroBanner>
  );
};

export const HeroBannerConfig = {
  emptyLabel: 'HeroBanner',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/hero-banner`,
} as Config<MappedComponentProperties>;

export const HeroBannerEditable = (props: HeroBannerProps) => (
  <EditableComponent config={HeroBannerConfig} {...props}>
    <HeroBanner {...props} />
  </EditableComponent>
);
